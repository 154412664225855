import React from 'react'
import PageTemplate from '../components/pageTemplate'

let Policy = () => (
  <PageTemplate title="Политика обработки персональных данных">                      
    <p>Дата публикации текущей версии документа: 03 мая 2021 г.</p>

    <h3>1. Общие положения</h3>
    <p>1.1. Политика обработки персональных данных (далее – Политика) ИП Моторина Сергея Сергеевича (далее – Предприниматель), разработана в соответствии с /1/, /2/, /3/, /4/, /5/, а также в соответствии с иными федеральными законами и подзаконными актами Российской Федерации, определяющими случаи и особенности обработки персональных данных и обеспечения безопасности и конфиденциальности такой информации (далее – Законодательство о персональных данных). При этом Предприниматель является Оператором обработки персональных данных.</p>

    <p>1.2. Политика разработана в целях реализации требований законодательства в области обработки и обеспечения безопасности персональных данных и направлена на обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных Предпринимателем.</p>

    <p>1.3. Политика устанавливает:</p>

    <ul>
    <li>цели обработки персональных данных;</li>
    <li>классификацию персональных данных и Субъектов персональных данных;</li>
    <li>общие принципы обработки персональных данных;</li>
    <li>основных участников системы управления процессом обработки персональных данных;</li>
    <li>основные подходы к системе управления процессом обработки персональных данных.</li>
    </ul>

    <p>1.4. Положения настоящей Политики являются основой для организации работы по обработке персональных данных Предпринимателем, в том числе, для разработки внутренних нормативных документов 2-го и 3-го уровня (регламентов, методик, технологических схем и пр.), регламентирующих процесс обработки персональных данных Предпринимателем.</p>

    <h3>2. Цели обработки персональных данных</h3>

    <p>2.1. Предприниматель осуществляет обработку персональных данных в целях:</p>

    <ul>
    <li>заключения с Субъектом персональных данных любых договоров и их дальнейшего исполнения;</li>
    <li>проведения акций, опросов, исследований;</li>
    <li>предоставления Субъекту персональных данных информации об оказываемых услугах, о разработке новых продуктов и услуг;</li> 
    <li>информирования Клиента о предложениях по продуктам и услугам;</li>
    <li>осуществления Предпринимателем Административно-хозяйственной деятельности;</li>
    </ul>

    <h3>3. Классификация персональных данных и Субъектов персональных данных</h3>

    <p>3.1. К персональным данным относится любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (Субъекту персональных данных), обрабатываемая Предпринимателем для достижения заранее определенных целей.</p>

    <p>3.2. Предприниматель не осуществляет обработку специальных категорий персональных данных, касающихся расовой и национальной принадлежности, политических взглядов, религиозных и философских убеждений, интимной жизни, судимости физических лиц, если иное не установлено законодательством Российской Федерации.</p>

    <p>3.3. Предприниматель вправе осуществлять обработку специальной категории персональных данных, касающейся состояния здоровья Субъекта персональных данных (застрахованных лиц и иных лиц, в случаях предусмотренных действующим законодательством).</p>

    <p>3.4. Предприниматель осуществляет обработку персональных данных следующих категорий Субъектов персональных данных:</p>
    <ul>
    <li>физические лица, представляющие интересы Корпоративного клиента Предпринимателя (Представители Корпоративного клиента);</li>
    <li>физические лица, являющиеся Розничными клиентами Предпринимателя;</li>
    <li>физические лица, приобретшие или намеревающиеся приобрести услуги Предпринимателя, услуги третьих лиц при посредничестве Предпринимателя или не имеющие с Предпринимателем договорных отношений при условии, что их персональные данные включены в автоматизированные системы Предпринимателя и обрабатываются в соответствии с Законодательством о персональных данных;</li>
    <li>физические лица, не относящиеся к Клиентам Предпринимателя, заключившие или намеревающиеся заключить с Предпринимателем договорные отношения в связи с осуществлением Предпринимателем Административно-хозяйственной деятельности при условии, что их персональные данные включены в автоматизированные системы Предпринимателя и обрабатываются в соответствии с Законодательством о персональных данных;</li>
    <li>физические лица, персональные данные которых сделаны ими общедоступными, а их обработка не нарушает их прав и соответствует требованиям, установленным Законодательством о персональных данных;</li>
    <li>иные физические лица, выразившие согласие на обработку Предпринимателем их персональных данных или физические лица, обработка персональных данных которых необходима Предпринимателем для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на Предприниматель функций, полномочий и обязанностей.</li>
    </ul>

    <h3>4. Общие принципы обработки персональных данных</h3>

    <p>4.1. Предприниматель осуществляет обработку персональных данных на основе общих принципов:</p>

    <ul>
    <li>законности заранее определенных конкретных целей и способов обработки персональных данных;</li>
    <li>обеспечения надлежащей защиты персональных данных;</li>
    <li>соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;</li>
    <li>соответствия объема, характера и способов обработки персональных данных целям обработки персональных данных;</li>
    <li>достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;</li>
    <li>недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
    <li>хранения персональных данных в форме, позволяющей определить Субъекта персональных данных, не дольше, чем этого требуют цели их обработки;</li>
    <li>уничтожения или обезличивания персональных данных по достижении целей их обработки, если срок хранения персональных данных не установлен законодательством Российской Федерации, договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект персональных данных;</li>
    <li>обеспечения конфиденциальности и безопасности обрабатываемых персональных данных.</li>
    </ul>

    <p>4.2. В рамках обработки персональных данных для Субъекта персональных данных и Предпринимателя определены следующие права.</p>

    <p>4.2.1. Субъект персональных данных имеет право:</p>

    <ul>
    <li>получать информацию, касающуюся обработки его персональных данных, в порядке, форме и сроки, установленные Законодательством о персональных данных;</li>
    <li>требовать уточнения своих персональных данных, их Блокирования или Уничтожения в случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными, не являются необходимыми для заявленной цели обработки или используются в целях, не заявленных ранее при предоставлении Субъектом персональных данных согласия на обработку персональных данных;</li>
    <li>принимать предусмотренные законом меры по защите своих прав;</li>
    <li>отозвать свое согласие на обработку персональных данных.</li>
    </ul>

    <p>4.2.2. Оргпизация имеет право:</p>

    <ul>
    <li>обрабатывать персональные данные Субъекта персональных данных в соответствии с заявленной целью;</li>
    <li>требовать от Субъекта персональных данных предоставления достоверных персональных данных, необходимых для исполнения договора, оказания услуги, идентификации Субъекта персональных данных, а также в иных случаях, предусмотренных Законодательством о персональных данных;</li>
    <li>ограничить доступ Субъекта персональных данных к его персональным данным в случае, если Обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма, доступ Субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц, а также в иных случаях, предусмотренных законодательством Российской Федерации;</li>
    <li>обрабатывать общедоступные персональные данные физических лиц;</li>
    <li>осуществлять обработку персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с законодательством Российской Федерации;</li>
    <li>поручить обработку персональных данных другому лицу с согласия Субъекта персональных данных.</li>
    </ul>


    <h3>5. Основные участники системы управления процессом обработки персональных данных</h3>

    <p>5.1. В целях осуществления эффективного управления процессом обработки персональных данных Предприниматель определяет, рассматривает и утверждает политику в отношении обработки персональных данных и выполняет следующие функции:</p>

    <ul>
    <li>разрабатывает, организует и контролирует процесс обработки персональных данных (осуществляемый с использованием средств автоматизации или без использования таких средств, в том числе на бумажных носителях) в соответствии с Законодательством о персональных данных, настоящей Политикой, внутренними нормативными документами Предпринимателя;</li>
    <li>осуществляет управление и постоянное совершенствование процесса обработки персональных данных по единым правилам, стандартизацию и тиражирование процесса;</li>
    <li>устанавливает состав ключевых показателей эффективности (КПЭ) на процесс, разрабатывает методики расчета, мониторинга КПЭ и прочих показателей;</li>
    <li>осуществляет анализ, оценку и прогноз рисков, связанных с обработкой персональных данных Предпринимателем, выработку мер по снижению рисков;</li>
    <li>осуществляет оценку влияния процессов на права и свободы субъектов персональных данных;</li>
    <li>осуществляет анализ автоматизированных систем и процессов обработки персональных данных на предмет соответствия установленным обязательным требованиям в области обработки и защиты персональных данных;</li>
    <li>осуществляет ведение учета процедур и средств обработки персональных данных;</li>
    <li>осуществляет контроль наличия и полноты содержания договоров поручения на обработку персональных данных, договоров на передачу персональных данных (DTA);</li>
    <li>осуществляет разработку и организацию применения правовых, организационных и технических мер защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также иных неправомерных действий в отношении персональных данных;</li>
    <li>осуществляет определение угроз безопасности персональных данных при их обработке;</li>
    <li>осуществляет организацию и контроль уровня защищенности информационных систем персональных данных;</li>
    <li>осуществляет оценку эффективности принимаемых мер по обеспечению безопасности персональных данных;</li>
    <li>разрабатывает внутренние процедуры, направленные на обеспечение безопасности и защиты персональных данных;</li>
    <li>организует и осуществляет внутренний контроль за соблюдением Законодательства о персональных данных, настоящей Политики, внутренних нормативных документов Предпринимателя, требований к защите персональных данных;</li>
    <li>организует прием и обработку обращений и запросов субъектов персональных данных или их представителей и (или) осуществляет контроль за приемом и обработкой таких обращений и запросов;</li>
    <li>осуществляет взаимодействие с органами государственной власти по вопросам защиты персональных данных;</li>
    <li>осуществляет уведомление надзорного органа в соответствии с применимыми требованиями о фактах утечки персональных данных;</li>
    <li>организует оповещение субъектов персональных данных о фактах утечки их персональных данных;</li>
    <li>в рамках проводимых контрольных процедур оценивает эффективность системы внутреннего контроля Предпринимателя по обеспечению соблюдения требований настоящей Политики, а также утвержденных нормативных документов Предпринимателя в отношении персональных данных;</li>
    <li>обеспечивает правовую защиту интересов Предпринимателя в судах и государственных органах по спорам, связанным с обработкой персональных данных, а также при рассмотрении административных дел, связанных с нарушением законодательства в указанной сфере.</li>
    </ul>
    
    <h3>6. Организация системы управления процессом обработки персональных данных</h3>

    <p>6.1. Обработка персональных данных Субъекта персональных данных осуществляется с его согласия на обработку персональных данных, а также без такового, если Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является Субъект персональных данных, а также для заключения договора по инициативе Субъекта персональных данных или договора, по которому Субъект персональных данных будет являться выгодоприобретателем или поручителем или в иных случаях, предусмотренных Законодательством о персональных данных.</p>

    <p>6.2. Обработка специальной категории персональных данных, касающейся состояния здоровья Субъекта персональных данных осуществляется с согласия Субъекта персональных данных на обработку своих персональных данных в письменной форме, а также без такового, если персональные данные сделаны общедоступными Субъектом персональных данных.</p>

    <p>6.3. Предприниматель вправе поручить обработку персональных данных другому лицу с согласия Субъекта персональных данных, если иное не предусмотрено федеральным законом. Такая Обработка персональных данных осуществляется только на основании договора, заключенного между Предпринимателем и третьим лицом, в котором должны быть определены:</p>

    <ul>
    <li>перечень действий (операций) с персональными данными, которые будут совершаться третьим лицом, осуществляющим обработку персональных данных;</li>
    <li>цели обработки персональных данных;</li>
    <li>обязанности третьего лица соблюдать конфиденциальность персональных данных и обеспечивать их безопасность при обработке, а также требования к защите обрабатываемых персональных данных.</li>
    </ul>

    <p>6.4. Предприниматель осуществляет передачу персональных данных государственным органам в рамках их полномочий в соответствии с законодательством Российской Федерации.</p>

    <p>6.5. Предприниматель несет ответственность перед Субъектом персональных данных за действия лиц, которым Предприниматель поручает обработку персональных данных Субъекта персональных данных.</p>

    <p>6.6. Обработка персональных данных прекращается при достижении целей такой обработки, а также по истечении срока, предусмотренного законом, договором, или согласием Субъекта персональных данных на обработку его персональных данных. При отзыве Субъектом персональных данных согласия на обработку его персональных данных, Предприниматель вправе продолжить обработку персональных данных без согласия Субъекта персональных данных, если такая обработка предусмотрена договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект персональных данных, иным соглашением между Предпринимателем и Субъектом персональных данных, либо если Предприниматель вправе осуществлять обработку персональных данных без согласия Субъекта персональных данных на основаниях, предусмотренных /4/, /5/ или другими федеральными законами.</p>

    <p>6.7. Обработка персональных данных осуществляется с соблюдением конфиденциальности, под которой понимается обязанность не раскрывать третьим лицам и не распространять персональные данные без согласия Субъекта персональных данных, если иное не предусмотрено законодательством Российской Федерации.</p>

    <p>6.8. Предприниматель обеспечивает конфиденциальность персональных данных Субъекта персональных данных со своей стороны, со стороны своих аффилированных лиц, имеющих доступ к персональным данным физических лиц, а также обеспечивает использование персональных данных вышеуказанными лицами исключительно в целях, соответствующих закону, договору или иному соглашению, заключенному с Субъектом персональных данных.</p>

    <p>6.9. Обеспечение безопасности обрабатываемых персональных данных осуществляется Предпринимателем в рамках единой комплексной системы организационно-технических и правовых мероприятий по защите информации, составляющей коммерческую тайну, с учетом требований Законодательства о персональных данных, принятых в соответствии с ним нормативных правовых актов. Система информационной безопасности Предпринимателя непрерывно развивается и совершенствуется на базе требований международных и национальных стандартов информационной безопасности, а также лучших мировых практик.</p>

    <h3>7. Заключительные положения</h3>

    <p>7.1. Предприниматель несет гражданско-правовую, административную и иную ответственность за несоблюдение принципов и условий обработки персональных данных физических лиц, а также за разглашение или незаконное использование персональных данных в соответствии с законодательством Российской Федерации.</p>

    <p>7.2. Политика является общедоступной и подлежит размещению на официальном сайте Предпринимателя или иным образом обеспечивается неограниченный доступ к настоящему документу.</p>

    <h3>ПРИЛОЖЕНИЕ 1.</h3>
    <p>Список терминов и определений</p>

    <p>Административно-хозяйственная деятельность – внутрикорпоративные процессы, направленные на текущее обеспечение деятельности Предпринимателя организатоварно-материальными ценностями (осуществление закупок канцтоваров, офисного оборудования, расходных материалов, хозяйственных товаров, услуг связи и т.п.); на организацию документооборота (ведение архива, библиотек, баз данных); на организацию эксплуатации зданий, помещений, территорий (содержание, уборка, оформление и ремонт помещений); на организацию рабочего процесса.</p>

    <p>Оператор (оператор обработки персональных данных) – ИП Моторин Сергей Сергеевич (ИНН: 772793800803, ОГРНИП: 320774600423887), осуществляющее обработку персональных данных, а также определяющий цели обработки персональных данных, состав персональных данных, подлежащих обработке, и действия, совершаемые с персональными данными.
    Близкие родственники – являются родственники по прямой восходящей и нисходящей линии (родители и дети, дедушки, бабушки и внуки), полнородные и неполнородные (имеющие общих отца или мать) братья и сестры</p>

    <p>Клиент – термин, используемый при совместном упоминании Корпоративного клиента и Розничного клиента.
    Корпоративный клиент – юридическое лицо, индивидуальный предприниматель, а также физическое лицо, занимающееся в установленном законодательством Российской Федерации порядке частной практикой, заключившее или намеревающееся заключить с Предпринимателем договор на оказание услуг.</p>

    <p>Обработка персональных данных – любое действие (операция) Оператора или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (Предоставление, доступ), Обезличивание, Блокирование, удаление и Уничтожение персональных данных. В рамках Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» установлены следующие определения:</p>

    <p>Распространение персональных данных – действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</p>

    <p>Розничный клиент – физическое лицо, которое заключило с Предпринимателем договор на оказание услуг, включая получение услуг путем присоединения к условиям публичного договора, и персональные данные которого переданы Предпринимателю.</p>

    <p>Субъект персональных данных – физическое лицо, которое прямо или косвенно определено с помощью персональных данных.</p>

    <p>Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>

    <p>Обезличивание персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</p>
    
    <p>Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
    
    <p>Уничтожение персональных данных – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.</p>
    
    <p>Представитель Корпоративного клиента – физическое лицо, персональные данные которого переданы Предпринимателю и </p>
    
    <ul>
    <li>входящее в органы управления Корпоративного Клиента;</li> 
    <li>являющееся владельцем/учредителем/акционером/участником Корпоративного клиента;</li> 
    <li>действующее от имени Корпоративного клиента на основании доверенности/указанное в карточке с образцами подписей и оттиска печати Корпоративного клиента.</li> 
    </ul>

    <h3>ПРИЛОЖЕНИЕ 2.</h3>
    <p>ПЕРЕЧЕНЬ ССЫЛОЧНЫХ ДОКУМЕНТОВ</p>

    <p>
    1. Конституция Российской Федерации.<br />
    2. Конвенция о защите физических лиц в отношении автоматизированной обработки данных личного характера (ETS N 108, заключена в г. Страсбурге 28.01.1981).<br />
    3. Трудовой кодекс Российской Федерации от 30.12.2001 № 197-ФЗ с учетом изменений и дополнений.<br />
    4. Федеральный закон от 27.07.2006 № 152-ФЗ «О персональных данных» с учетом изменений и дополнений.<br />
    5. Регламент № 2016/679 Европейского парламента и Совета Европейского Союза «О защите физических лиц при обработке персональных данных и о свободном обращении таких данных, а также об отмене Директивы 95/46/ЕС (Общий Регламент о защите персональных данных)» (принят в г. Брюсселе 27.04.2016).
    </p>

    <br /><br />
    <p><strong>
    ИП Моторин Сергей Сергеевич<br />
    ИНН: 772793800803, ОГРНИП: 320774600423887
    </strong></p>
  </PageTemplate>
)

export default Policy
